import React, { useState } from "react";
import { graphql } from "gatsby";
import { PortableTextBlock } from "../components/sanity/portableTextBlock";
import CareerBlock from "../components/blockZone//blocks/careerBlock";
import { StandardModal } from "../components/ui/standardModal";
import { useSiteConfig } from "../GraphQl/useSiteConfig";
import { Application } from "../components/forms/application";
import { PageMeta } from "../components/pageMeta";

function CareerPageTemplate({ data: { page } }) {
  const { opportunity, description, blockOne, blockTwo, slug, meta } =
    page || {};
  const { careerFormText } = useSiteConfig();
  const [modalOpen, setModalOpen] = useState(
    typeof window === "undefined" ? true : false
  );

  return (
    <>
      <PageMeta {...meta} />
      <div className="mt-44 md:mt-56">
        <div className="lg:grid grid-cols-15 mb-16 md:mb-36 px-gutter lg:px-0 ">
          <h1 className="col-start-3 col-span-2 text-sm font-bold mb-2 md:mb-4">
            OPPORTUNITY
          </h1>
          <h1 className="col-start-3 col-span-8 text-3xl md:text-6xl font-sans mb-4 md:mb-10">
            {opportunity}
          </h1>
          <p className="col-start-3 col-end-13 text-base lg:text-3xl leading-8 lg:leading-10 ">
            {description}
          </p>
        </div>
        <div className="lg:grid grid-cols-15 px-gutter lg:px-0">
          <div className="col-start-3 col-end-8 mb-12 md:mb-20 lg:mb-0">
            <h1 className="text-xl md:text-3xl font-sans mb-2 md:mb-4">
              What you'll do
            </h1>
            <PortableTextBlock text={blockOne} />
          </div>
          <div className="col-start-9 col-end-14">
            <h1 className="text-xl mb-2 md:text-3xl font-sans md:mb-4">
              What you'll need
            </h1>
            <PortableTextBlock text={blockTwo} />
          </div>
        </div>
        <div className="flex flex-col my-28 md:my-48 px-gutter lg:px-0">
          <h1 className="text-3xl font-sans  mb-4 text-center">
            Does this sound like you? We would love to hear from you.
          </h1>
          <button
            onClick={() => setModalOpen(true)}
            className="outline-btn self-center mt-6"
          >
            Apply now
          </button>
        </div>
        <CareerBlock slug={slug?.current} />
        <StandardModal isOpen={modalOpen}>
          {modalOpen && (
            <Application
              careerFormText={careerFormText}
              setModalOpen={setModalOpen}
              formName={opportunity}
            />
          )}
        </StandardModal>
      </div>
    </>
  );
}

export default CareerPageTemplate;

export const careerQuery = graphql`
  query careerQuery($slug: String!) {
    page: sanityCareer(slug: { current: { eq: $slug } }) {
      opportunity
      description
      blockOne: _rawBlockOne(resolveReferences: { maxDepth: 10 })
      blockTwo: _rawBlockTwo(resolveReferences: { maxDepth: 10 })
      meta {
        ...MetaCardFields
      }
      slug {
        current
      }
    }
  }
`;
